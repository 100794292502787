<template>
  <BaseLayout>
    <Breadcrumbs />
    <div class="container">
      <b-row class="forgot-password">
        <b-col md="10" lg="7">
          <h1>{{ $t("password_forgot") }}</h1>
          <p>
            {{ $t("forgot_password_text") }}
          </p>
          <b-form-group
            :label="$t('form_email') + '*'"
            label-for="email"
            :description="description"
            class="align-items-md-center d-flex"
          >
            <b-form-input
              id="email"
              v-model="email"
              type="text"
              :state="emailState"
              aria-describedby="email-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="email-feedback">
              {{ emailError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <div
            class="
              btn-wrap
              d-flex
              justify-content-md-between
              align-items-md-center
            "
          >
            <b-link class="back" href="/login">{{ $t("go_back") }}</b-link>
            <div class="btn-holder">
              <b-button
                @click="formSubmit"
                variant="info"
                class="text-white form-submit-btn"
                >{{ $t("reset_my_password") }}</b-button
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/base/core/components/common/BaseLayout";
import Breadcrumbs from "@/base/core/components/Breadcrumbs";
import { forgotPassword } from "@storefront/core/data-resolver/user";
export default {
  name: "ForgotPassword",
  components: {
    BaseLayout,
    Breadcrumbs,
  },
  mounted() {
    const bcrumb = { current: this.$t("password_forgot"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("password_forgot") },
        { name: "keywords", content: this.$t("password_forgot") },
        { name: "description", content: this.$t("password_forgot") },
      ],
      title: this.$t("password_forgot"),
    };
  },
  data() {
    return {
      email: "",
      emailState: null,
      emailError: "",
      description: "",
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  methods: {
    async formSubmit() {
      if (this.validateEmail()) {
        const retval = await forgotPassword(this.email);
        if (retval == true) {
          this.description = this.$t("reset_password_email_has_been_sent");
        } else {
          this.description = this.$t(
            "reset_password_email_could_not_been_sent"
          );
        }
      }
    },
    validateEmail() {
      if (this.email == "") {
        this.emailState = false;
        this.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.email)) {
        this.emailState = false;
        this.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      this.emailState = true;
      return true;
    },
  },
};
</script>
